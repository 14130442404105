import React from "react";
import { Avatar } from "@material-ui/core";

import "./SidebarRow.css";

function SidebarRow({ src, Icon, title, onClick }) {
  return (
    <div className="sidebarRow">
      {src && <Avatar src={src} />}
      {Icon && <Icon />}

      <h4 onClick={onClick}>{title}</h4>
    </div>
  );
}

export default SidebarRow;
