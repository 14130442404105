import "./App.css";
import React, { useState, useMemo } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import Login from "./Login";
import { UserContext } from "./UserContext";
import Dashboard from "./Dashboard";

const App = () => {
  const [user, setUser] = useState(null);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <div className="App">
      <BrowserRouter>
        <UserContext.Provider value={value}>
          <Route exact path="/" component={Login}></Route>

          <Route path="/dashboard" component={Dashboard}></Route>

          <Route path="/Login" component={Login}></Route>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;
