import React, { useEffect } from "react";
import "./Preview.css";

function Preview({ type }) {
  useEffect(() => {
    //alert(type);
    LoadPhotos(type);
  }, [type]);

  const LoadPhotos = async (type) => {
    //alert(type);
  };

  return (
    <div class="preview">
      <div class="preview__body">This is preview{type} </div>
    </div>
  );
}

export default Preview;
