import React from "react";
import "./Header.css";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import SaveIcon from "@material-ui/icons/Save";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { Avatar, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ForumIcon from "@material-ui/icons/Forum";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { styled } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ImportExportIcon from "@material-ui/icons/ImportExport";

function Header() {
  const MyVisibility = styled(VideoLibraryIcon)({
    color: "white",
  });

  const AddIconStyled = styled(AddIcon)({
    color: "white",
  });

  const NotificationIconStyled = styled(NotificationsActiveIcon)({
    color: "white",
  });
  const WhiteTextTypography = withStyles({
    root: {
      color: "white",
      fontWeight: "bolder",
    },
  })(Typography);

  return (
    <div className="header">
      <div className="header__left">
        <MyVisibility fontSize="large" />
        <WhiteTextTypography>VidSolo</WhiteTextTypography>
        <div className="header__input">
          <SearchIcon />
          <input placeholder="Search" type="text" />
        </div>
      </div>
      <div className="header__center">
        <div className="header__option header__option--active">
          <HomeIcon fontSize="large" />
        </div>
        <div className="header__option">
          <ImportExportIcon fontSize="large" />
        </div>
        <div className="header__option">
          <SaveIcon fontSize="large" />
        </div>
        <div className="header__option">
          <FolderOpenIcon fontSize="large" />
        </div>
      </div>
      <div className="header__right">
        <div className="header__info"></div>
        <IconButton>
          <AddIconStyled />
        </IconButton>
        <IconButton>
          <NotificationIconStyled />
        </IconButton>
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default Header;
