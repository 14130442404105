import React, { useState } from "react";
import SidebarRow from "./SidebarRow";
import "./LeftMenu.css";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import Preview from "./Preview";

function LeftMenu() {
  const [isPreview, setIsPreview] = useState(false);
  const [contentTypeValue, setcontentTypeValue] = useState("");

  const onClickButton = (e) => {
    setIsPreview(true);
    // alert(e.target.innerText);
    setcontentTypeValue(e.target.innerText);
    //alert(contentType);
  };

  return (
    <div className="main">
      <div className="sidebar">
        <SidebarRow
          onClick={onClickButton}
          Icon={EmojiFlagsIcon}
          title="Photos"
        />
        <SidebarRow
          onClick={onClickButton}
          Icon={EmojiFlagsIcon}
          title="Videos"
        />
        <SidebarRow
          onClick={onClickButton}
          Icon={EmojiFlagsIcon}
          title="Elements"
        />
        <SidebarRow
          onClick={onClickButton}
          Icon={EmojiFlagsIcon}
          title="Textures"
        />
        <SidebarRow
          onClick={onClickButton}
          Icon={EmojiFlagsIcon}
          title="Uploads"
        />
      </div>
      <div className="leftMenu__Preview">
        {isPreview && <Preview type={contentTypeValue} />}
      </div>
    </div>
  );
}

export default LeftMenu;
