import React, { useContext, useEffect } from "react";
import { UserContext } from "./UserContext";
import "./Dashboard.css";
import Header from "./Header";
import LeftMenu from "./LeftMenu";

function Dashboard() {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    setUser(user);
    if (user != null) {
      localStorage.setItem("user", user);
    }
  }, []);

  return (
    <div className="dashboard">
      <div class="dashboard__header">
        <Header />
      </div>
      <div className="app__body">
        <LeftMenu />
      </div>
    </div>
  );
}

export default Dashboard;
